import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "@/pages/Home/Home";

const BasicRoute = () => (
  <Router>
    <Switch>
      <Route exact path="/index" component={Home} />
    </Switch>
  </Router>
);

export default BasicRoute;
