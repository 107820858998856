import React from "react";
import Router from "./Router";
import info from "../package.json";

console.log(info.version);
if (process.env.REACT_APP_ENV === "production") {
  console.log = function () {};
}
console.log(process.env);
function App() {
  return <Router />;
}

export default App;
