import React, { useEffect } from "react";
import md5 from "md5";
import { base58_to_binary } from "base58-js";
import CryptoJS from "crypto-js";

function Home(props) {
  const { search } = props.location;
  const location = window.location;
  console.log(location);
  console.log("params", search);
  // let domain = ''
  // if(window.location.host.includes(":")){
  //   domain = window.location.protocol + '//' + window.location.host.split(":")[0]
  // } else {
  //   domain = window.location.protocol + '//' + window.location.host
  // }
  let domain = window.location.protocol + "//" + window.location.host;
  console.log(domain);
  async function getApi(token) {
    const url = domain + "/check";
    //const url = "https://wwww.1111wdty.com/check";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "token=" + token,
    });
    const json = await response.json();
    return json;
  }
  async function domainStat(did) {
    const url = domain + "/stat/domain";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "did=" + did,
    });
    const json = await response.json();
    return json;
  }
  function Uint8ArrayToString(fileData) {
    var dataString = "";
    for (var i = 0; i < fileData.length; i++) {
      dataString += String.fromCharCode(fileData[i]);
    }

    return dataString;
  }

  function getSpeedWithImg(imgUrl, fileSize) {
    return new Promise((resolve, reject) => {
      let start = null;
      let end = null;
      let img = document.createElement("img");
      start = new Date().getTime();
      img.onload = function (e) {
        end = new Date().getTime();
        const speed = (fileSize * 1000) / (end - start);
        resolve(speed);
      };
      img.onerror = function(e){
        resolve(10000000)
      }
      img.src = imgUrl;
    }).catch((err) => {
      throw err;
    });
  }
  const cb = (arr) => {
    console.log(arr);
    const min = Math.min.apply(
      null,
      arr.map((item) => {
        console.log(item);
        return item.speed;
      })
    );
    console.log("min", min);
    const minItem = arr.find((item) => item.speed === min);
    console.log(minItem);
    domainStat(minItem.id)
    window.location.href = minItem.domain + search;
  };
  useEffect(() => {
    const token = md5(md5(new Date().getTime()));
    getApi(token).then((res) => {
      console.log("getApi", res);

      const data = res.data;
      const st = data.substring(0, 21);
      const list = data.substring(21);
      console.log(st);
      console.log(list);
      const st_decode = Uint8ArrayToString(base58_to_binary(st));
      const st_decode2 = st_decode.substring(st_decode.length - 2);
      console.log("token", token);
      console.log("st_decode2", st_decode2);
      //const list_decode = Uint8ArrayToString(base58_to_binary(list));
      console.log(st_decode);
      const endLen = 16 + Number(st_decode2);

      const key = token.substring(st_decode2, endLen);
      console.log("key", key);

      var decrypted = CryptoJS.AES.decrypt(list, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      console.log(JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));
      const siteArr = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      // const siteArr = [
      //   { domain: "https://www.wd88.bet/#/" },
      //   { domain: "https://www.123.bet/#/" },
      // ];
      const arr = [];
      siteArr.forEach((ele, index) => {
        //判断域名结尾
        //域名结尾是 /#/ 替换成空
        //域名结尾是 / 替换成空
        const _domain = ele.domain.includes("/#/")
          ? ele.domain.replace("/#/", "")
          : ele.domain.endsWith("/")
          ? ele.domain.substring(0, ele.domain.length - 1)
          : ele.domain;
        //console.log(_domain + "/image/spacer.png");
        getSpeedWithImg(_domain + "/image/spacer.png", 0.095).then((speed) => {
          console.log("speed", speed);
          arr.push({
            id: ele.id,
            domain: ele.domain,
            speed,
          });
          console.log(arr, siteArr);
          if (arr.length === siteArr.length) {
            cb(arr);
          }
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default Home;
